import React from "react";
import Tilt from "react-tilt";
import tw, { styled } from "twin.macro";

const Image = styled.img`
  ${tw`rounded-full transform transition shadow`}
`;

const Container = styled.a`
  ${tw`md:w-48 flex flex-col m-10`}
`;

const Name = styled.label`
  ${tw`text-center text-lg mt-2`}
`;

const Role = styled.label`
  ${tw`text-center text-gray-500`}
`;

export default function Portrait({ characterId, name, role, className }) {
  return (
    <Container
      className={"group " + className}
      href={`https://evewho.com/character/${characterId}`}
    >
      <Tilt>
        <Image
          src={`https://images.evetech.net/characters/${characterId}/portrait?size=256`}
        />
      </Tilt>
      <Name>{name}</Name>
      <Role>{role}</Role>
    </Container>
  );
}
