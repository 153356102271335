import Particles, {
  MoveDirection,
  OutMode,
  ShapeType,
} from "react-particles-js";
import styled from "styled-components";
import tw from "twin.macro";

export default styled(Particles).attrs({
  params: {
    particles: {
      color: {
        value: "#ffffff",
      },
      lineLinked: {
        enable: true,
      },
      move: {
        bounce: false,
        direction: MoveDirection.none,
        enable: true,
        outMode: OutMode.out,
        random: true,
        speed: 0.3,
        straight: false,
      },
      opacity: {
        anim: {
          enable: true,
          minimumValue: 0,
          speed: 1,
          sync: false,
        },
        random: true,
        value: 1,
      },
      shape: {
        type: ShapeType.circle,
      },
      size: {
        random: true,
        value: 3,
      },
    },
  },
})`
  ${tw`w-full h-full absolute top-0 left-0`}
  z-index: 0;
`;
